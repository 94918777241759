export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'admin-dashboard',
  },
  {
    title: 'Client',
    icon: 'UsersIcon',
    route: 'client-list',
  },
  {
    title: 'DataBase',
    icon: 'DatabaseIcon',
    children: [
      
      {
        title: 'Email Templates',
        route: 'email-list',
      },
      {
        title: 'Attendance Status',
        route: 'attendance-status'
      },
      {
        title: 'Payroll Paid',
        route: 'payroll-paid'
      },
      {
        title: 'Job Title',
        route: 'job-title'
      },
      {
        title: 'Location',
        route: 'location'
      },
      
    ],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'General',
        route: 'general-setting',
      },
      {
        title: 'App',
        route: 'app-setting',
      }
    ],
  },
  {
    title: 'Feedback Panel',
    icon: 'AirplayIcon',
    children: [
      {
        title: 'Background',
        route: 'master-backgrounds',
      },
      {
        title: 'Others',
        route: 'feedback-panel-config',
      }
    ]
  },
  {
    title: 'Holiday',
    icon: 'BriefcaseIcon',
    route: 'admin-holiday',
  },
  {
    title: 'Role Menu',
    icon: 'MenuIcon',
    route: 'role-menu',
  },
  {
    title: 'Broadcast Message',
    icon: 'RadioIcon',
    route: 'broadcast-message',
  },
]
