/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import store from '../../store'
import dashboard from './dashboard'

import clientdashboard from './clientdashboard'
import siteclientdashboard from './siteclientdashboard'


import operation_manager from './operation_managerdashboard'

var item = [];

if (store.getters.currentUser.role == 'super_admin') {
	item = dashboard
}else if(store.getters.currentUser.role == 'admin') {

	item = allowedMenus();
	//item = clientdashboard
}else if(store.getters.currentUser.role == 'sub_admin') {
	//item = clientdashboard
	item = allowedMenus();
}else if(store.getters.currentUser.role == 'site_client' || store.getters.currentUser.role == 'site_manager'|| store.getters.currentUser.role == 'supervisor') {
	//item = siteclientdashboard
	item = allowedMenus();
}else if(store.getters.currentUser.role == 'operation_manager'){
	  //item = clientdashboard
	item = allowedMenus();
}else{
	item = allowedMenus();
}

function allowedMenus() {
	var permissions = [];

	store.getters.currentUser.menu_permission.forEach(menu => {
		if(menu.access == true){

			var p_permission = [];

			// level 1
			if(menu.level == 1){
				menu.permissions.forEach(p_menu => {
					if(p_menu.access == true){
						p_permission.push(p_menu);
					}
				});
				menu.permissions = p_permission;
			}

			// level 2
			if(menu.level == 2){
				menu.children.forEach(child => {
					if(child.access == true){

						// child menu permission
						var ch_access = [];
						child.permissions.forEach(c => {
							if(c.access == true){
								ch_access.push(c);
							}
						});
						child.permissions = ch_access;

						p_permission.push(child);
					}
				});
				menu.children = p_permission;
			}

			// level 3
			if(menu.level == 3){
				menu.children.forEach(child => {

				  if(child.access == true){

					// child menu permission
					var ch_access_l3 = [];
					var ch_access_c3 = [];
					if(child.permissions){
					  child.permissions.forEach(c => {
						if(c.access == true){
						  ch_access_l3.push(c);
						}
					  });
					  child.permissions = ch_access_l3;
					}

					else if(child.children){
					  var ch_access_k1 = [];
					  child.children.forEach(sub => {
						if(sub.access == true){
						  // sub.permissions.forEach(k => {
						  //   if(k.access == true){
							  ch_access_k1.push(sub);
						  //   }
						  // });
						  ch_access_c3 = ch_access_k1;
						}
						child.children = ch_access_c3;
					  });
					}
					p_permission.push(child);
				  }
				});
				menu.children = p_permission;
			  }

			permissions.push(menu);
		}
	});
	return permissions;
}

export default [
...item
]


