<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="width:190px;"

            />

              <!-- commented after updating new logo -->
              <!-- <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                  style="width:190px"

                />
              </span>
              <h2 class="brand-text pl-0" style="font-size: 18px">
                {{ appName }}
              </h2> -->
            </b-link>
          </li>

          <!-- Toggler Button -->
          
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navList"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { GET_API, POST_API } from "../../../../../store/actions.type"
import Bus from "../../../../../event-bus";


export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  data(){
    return {
      navList : navMenuItems,
    }
  },
  methods :{
    //Code to update counter
    counterUpdate(){
      return this.$store.dispatch(POST_API, {
          data:{
              role : this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
              site:this.$store.getters.currentUser.site ?this.$store.getters.currentUser.site._id:null
          },
          api : '/api/pending-report-counts'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message        = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;

              window.scrollTo(0,0);
          } else {
              var data  = this.$store.getters.getResults.data;
              for (var i = 0; i < this.navList.length ; i++) {

                if (this.navList[i].title == 'Reports') {

                   this.navList[i].tag = (data.csdr + data.csdropt + data.mosquito + data.bhsc + data.bhscn + data.mrd + data.awcr + data.awcrn + data.shs + data.pb + data.sd + data.tft + data.ft + data.ts + data.af + data.hsb + data.hss + data.wl + data.mp + data.stinv + data.tic + data.qsa + data.flt + data.exc);
                   
                   this.navList[i].tagVariant = 'danger';

                   for(var j = 0 ;j < this.navList[i].children.length;j++){
                      if (this.navList[i].children[j].title == 'Custom Forms') {

                        this.navList[i].children[j].tag = data.csdr + data.csdropt + data.mosquito + data.bhsc + data.bhscn + data.mrd + data.awcr + data.awcrn + data.shs + data.pb + data.hsb + data.hss + data.wl + data.mp+ data.stinv + data.tic + data.qsa + data.sd + data.tft + data.ft + data.ts + data.af + data.flt + data.exc;

                        this.navList[i].children[j].tagVariant = 'primary';
                      }
                   }
                }

                if (this.navList[i].title == 'Inventory') {

                   this.navList[i].tag = (data.inventory_order);
                   
                   this.navList[i].tagVariant = 'danger';

                   for(var j = 0 ;j < this.navList[i].children.length;j++){
                      if (this.navList[i].children[j].title == 'Overview') {

                        this.navList[i].children[j].tag = data.inventory_order;

                        this.navList[i].children[j].tagVariant = 'primary';
                      }
                   }
                }

              }
          }
        });
    }
  },
  mounted(){
    this.counterUpdate();

    Bus.$on('counter_update', () => {
      this.counterUpdate();
    })
                  
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
